import React, { PureComponent } from "react"

import Picture from "./Picture"

import { env, utils } from "../constants"



/* Component Payout */
class Payout extends PureComponent {

    render = () => {

        const { game, currency, gameJackpot } = this.props
        const { min, maxPay, maxBonus, minBonus } = game

        return (
            <div className="payout-container">
                <div className="payout">

                    <div className="payout-head">{utils.translate("Maximum payout")} <span>{utils.getWithCurrency(maxPay, currency)}</span></div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("ACE KING")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">A</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">K</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                        </div>
                        <div className="payout-value">1:1</div>
                    </div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("PAIR")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">K</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">K</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                        </div>
                        <div className="payout-value">1:1</div>
                    </div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("TWO PAIRS")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">5</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">5</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">2:1</div>
                    </div>


                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("THREE OF A KIND")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">3:1</div>
                    </div>

                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("STRAIGHT")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">A</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">3</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">5</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                        </div>
                        <div className="payout-value">4:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">3</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">6</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">9</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">5:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FULL HOUSE")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">2</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">7:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("FOUR OF A KIND")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Clubs" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">4</div>
                                <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spades" />
                            </div>
                        </div>
                        <div className="payout-value">20:1</div>
                    </div>


                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("STRAIGHT FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">6</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">7</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">8</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">9</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">50:1</div>
                    </div>



                    <div className="payout-box">
                        <div className="payout-name">{utils.translate("ROYAL FLUSH")}</div>
                        <div className="payout-combination">
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">10</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">J</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">Q</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">K</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                            <div className="payout-combination-box">
                                <div className="payout-combination-text">A</div>
                                <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                            </div>
                        </div>
                        <div className="payout-value">100:1</div>
                    </div>

                </div>

                <div className="bonus-payout" style={{ display: gameJackpot ? "block" : "none" }}>

                    <div className="bonus-payout-head">{utils.translate("BONUS")}</div>

                    <div className="bonus-payout-rate">{utils.getWithCurrency(minBonus, currency)} - {utils.getWithCurrency(maxBonus, currency)}</div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("ROYAL FLUSH")}</div>
                        <div className="bonus-payout-value jackpot-value">JACKPOT</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("STRAIGHT FLUSH")}</div>
                        <div className="bonus-payout-value">1500:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FOUR OF A KIND")}</div>
                        <div className="bonus-payout-value">200:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FULL HOUSE")}</div>
                        <div className="bonus-payout-value">70:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("FLUSH")}</div>
                        <div className="bonus-payout-value">50:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("STRAIGHT")}</div>
                        <div className="bonus-payout-value">40:1</div>
                    </div>

                    <div className="bonus-payout-box">
                        <div className="bonus-payout-name">{utils.translate("THREE OF A KIND")}</div>
                        <div className="bonus-payout-value">10:1</div>
                    </div>

                    <div className="bonus-payout-box-maxpay">{utils.translate("The maximum payout is the")} <span>JACKPOT</span></div>

                </div>
            </div>
        )
    }

}

export default Payout