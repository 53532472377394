import React, { Component } from "react"

/* Count Up */
import CountUp from 'react-countup'

/* Constants */
import { env, utils } from "../constants"

/* Components */
import Picture from "./Picture"



/* Component Bonus Win */
class BonusWin extends Component {

    render = () => {

        const { data, currency } = this.props

        if (data && Array.isArray(data) && data.length > 0) {

            let total = 0
            data.forEach((item => {
                total = total + item.total
            }))

            if (total === 0) {
                return <div />
            }

            const cindex = utils.currencies.findIndex(e => String(e.code).toLowerCase() === String(currency).toLowerCase())
            let symbol = ""
            let isAfter = 0

            if (cindex > -1) {

                if (utils.currencies[cindex].isAfter === 0) {
                    symbol = `${utils.currencies[cindex].symbol} `
                }
                else {
                    symbol = ` ${utils.currencies[cindex].symbol}`
                }

                isAfter = utils.currencies[cindex].isAfter
            }

            return (
                <div className={`bonus--win-container ${data.length === 2 ? 'bonus--double-win-container' : ''}`}>

                    {data.map((item, index) => {

                        if (item.total === 0) {
                            return <div key={`${index}`} />
                        }

                        return (
                            <div className="bonus--win-box" key={`${index}`}>

                                <div className="bonus--win-box-number">BOX {item.box + 1}</div>

                                <div className="bonus--win-game">{utils.translate(item.combination)}</div>
                                <CountUp end={item.total} duration={1} separator="," decimals={2} decimal="." prefix={isAfter === 0 ? symbol : ''} suffix={isAfter === 1 ? symbol : ''} className="bonus--win-value" />

                                <div className="bonus--win-coin">
                                    <Picture src={`${env.cpoint}/jackpot/dollar.png`} alt="Dollar" />
                                </div>

                                <div className="bonus--win-coins">
                                    <Picture src={`${env.cpoint}/jackpot/dollars.png`} alt="Dollars" />
                                </div>

                                <div className="bonus--win-money">
                                    <Picture src={`${env.cpoint}/jackpot/money.png`} alt="Money" />
                                </div>

                            </div>
                        )
                    })}

                    {total > 0 &&
                        <div className="firework-container">
                            <div className="firework" />
                            <div className="firework" />
                            <div className="firework" />
                            <div className="firework" />
                            <div className="firework" />
                            <div className="firework" />
                        </div>
                    }

                </div>
            )
        }

        return <div />
    }

}

export default BonusWin